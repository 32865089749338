import React from 'react'
import Input from '@components/input'
import Textarea from '@components/Textarea'
import { useFormikContext } from 'formik'
import connectText from '@hooks/connectText'
import useTranslation from 'next-translate/useTranslation'

const ContactMessageInputs = ({ ref }) => {
    const { t } = useTranslation()
    const { setFieldValue } = useFormikContext()
    const connect = connectText('common:home')
    return (
        <div className="flex flex-col gap-3 2xl:gap-5 w-full">
            <div className="flex flex-col gap-2">
                <div className="text-md">{t(connect('email'))}*</div>
                <Input
                    name={'email'}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    className="bg-white h-8 border-none focus:ring-0 w-full text-sm"
                    ref={ref}
                />
            </div>
            <div className="flex flex-col gap-2">
                <div className="text-md">{t(connect('message'))}</div>
                <Textarea
                    name={'message'}
                    onChange={(e) => setFieldValue('message', e.target.value)}
                    className="bg-white border-none outline-none focus:ring-0 h-24 w-full text-sm"
                ></Textarea>
            </div>
        </div>
    )
}

export default ContactMessageInputs
