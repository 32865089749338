import React from 'react'
import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'

export default function RenderMeta({ title, description, img = '' }) {
    const { t } = useTranslation()
    const subtitle = t('common:toolbar.upsilon') + ' - ' + title
    title = title ? subtitle : t('common:toolbar.upsilon')
    description = description || t('common:about.about.info')

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={img} />
            <meta property="og:image:url" content={img} />
            <meta
                property="og:image:alt"
                content={t('common:toolbar.upsilon')}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@upsilon" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={img} />
        </Head>
    )
}
