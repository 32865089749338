import Button from '@components/button'
import connectText from '@hooks/connectText'
import useTranslation from 'next-translate/useTranslation'
import { useDispatch } from 'noval'
import React from 'react'

const Solutions = () => {
    const { t } = useTranslation()
    const connect = connectText('common:home.solutions')
    const { dispatch } = useDispatch()

    return (
        <div className="flex flex-col gap-6 lg:gap-14 items-center py-8 px-3 lg:py-20 lg:px-6">
            <div className="flex flex-col items-center gap-1 lg:gap-4">
                <div className="text-xl lg:text-2xl font-bold capitalize">
                    {t(connect('ourSolutions'))}
                </div>

                <div className="flex flex-col items-center gap-1">
                    <div className="text-[20px] lg:text-xl text-grey-800 w-full text-center">
                        {t(connect('description'))}
                    </div>
                    <div className="text-[20px] lg:text-xl text-grey-800 w-full text-center">
                        {t(connect('serviceDescription'))}
                    </div>
                </div>
                <Button
                    greyLight
                    customClassName={'mt-2 text-[14px] lg:text-base'}
                    onClick={() => dispatch({ contactMessage: true })}
                >
                    {t(connect('learnMore'))}
                </Button>
            </div>

            <div className="flex flex-wrap w-full justify-center mt-4">
                {solutionsOptions.map(({ name, img }) => {
                    return (
                        <div
                            key={name}
                            className="w-full md:w-1/2 lg:w-1/3 px-5 flex flex-col gap-3 lg:gap-8 mb-10"
                        >
                            <div
                                className={`${
                                    !img && 'bg-grey-200'
                                } md:h-[226px] lg:h-[326px] `}
                            >
                                {/* md:h-[226px] lg:h-[13.5rem] xl:h-[17rem] 2xl:h-[21.5rem] */}
                                <img
                                    src={img}
                                    alt={name}
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="flex items-center">
                                <div className="bg-grey-200 !text-md lg:text-xl font-bold capitalize">
                                    {t(connect(name))}
                                </div>
                            </div>
                            <div className="text-grey-700 !text-md lg:text-xl">
                                {t(connect(`${name}Description`))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const solutionsOptions = [
    {
        name: 'surveyDesign',
        img: 'img/home/solutions/create-survey.png'
    },
    {
        name: 'surveyReview',
        img: 'img/home/solutions/review.png'
    },
    {
        name: 'dataCollection',
        img: 'img/home/solutions/data-collection.png'
    },
    {
        name: 'dataReview',
        img: 'img/home/solutions/audit.png'
    },
    {
        name: 'analysis',
        img: 'img/home/solutions/analysis.png'
    },
    {
        name: 'reports',
        img: 'img/home/solutions/reporting.png'
    }
]

export default Solutions
