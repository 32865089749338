import Button from '@components/button'
import connectText from '@hooks/connectText'
import { buildClass } from '@util/tag'
import { Form, Formik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { useDispatch, useSelector } from 'noval'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import ContactMessageInputs from './contact-message-inputs'
import PrimaryButton from '@components/shared/primary-buttons/primary-button'
import LoadingFullScreen from '@components/shared/LoadingFullScreen'

const ContactMessage = () => {
    const { t, lang } = useTranslation()
    const { dispatch } = useDispatch()
    const connect = connectText('common:home')
    const connectErrors = connectText('common:researcher.errors')
    const contactMessage = useSelector('contactMessage', false)
    const [messageModal, setMessageModal] = useState(false)
    const [msgSent, setMsgSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const ref = useRef(null)

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t(connectErrors('emailInvalid')))
            .required(t(connectErrors('required'))),
        message: Yup.string().required(t(connectErrors('required')))
    })

    const handleSubmit = (values) => {
        axios.post(
            process.env.NEXT_PUBLIC_SURVEY_REST_APT_URL + '/sendContactMessage',
            values,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: '*/*'
                }
            }
        )

        setMsgSent(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    useEffect(() => {
        contactMessage && setMessageModal(true)
    }, [contactMessage])

    useEffect(() => {
        messageModal && ref?.current?.focus()
        !messageModal && dispatch({ contactMessage: false })
    }, [messageModal])

    return (
        <div className="relative">
            <Button
                customClassName={buildClass(
                    'hover:opacity-70 !px-0 !py-0',
                    lang === 'en' && 'transform rotate(180deg)'
                )}
                onMouseEnter={() => setMessageModal(true)}
                onClick={() => setMessageModal(false)}
            >
                <img
                    src={
                        lang === 'ar'
                            ? '/img/logos/upsilon-button-ar.png'
                            : '/img/logos/upsilon-button-en.png'
                    }
                    className={buildClass('w-24')}
                    alt="upsilon"
                />
            </Button>

            <div
                className={buildClass(
                    'bg-grey-400 shadow absolute bottom-2 w-[450px] 2xl:w-[600px]',
                    lang === 'ar' ? 'left-20' : 'right-20',
                    !messageModal && 'hidden',
                    'flex flex-col gap-2 2xl:gap-5 items-center py-3 2xl:py-10 !z-[999]'
                )}
                tabIndex={0}
            >
                <img src="/img/logos/upsilon-logo.svg" className="w-40" />
                <div className="text-md">{t(connect('contactModalTitle'))}</div>
                <Formik
                    initialValues={{
                        email: '',
                        message: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        setLoading(true)
                        handleSubmit(values)
                    }}
                >
                    {({ submitForm }) => {
                        return (
                            <Form className="w-[90%] pb-6">
                                {loading ? (
                                    <LoadingFullScreen />
                                ) : msgSent ? (
                                    <div className="flex flex-col 2xl:gap-2 items-center justify-center bg-white px-5 lg:px-10 h-52">
                                        <img
                                            src="/img/GIFs/Done.gif"
                                            className="w-24"
                                        />
                                        <div className="text-md capitalize">
                                            {t('common:FAQ.sent')}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col gap-1 2xl:gap-5 w-full px-5 lg:px-5">
                                        <ContactMessageInputs ref={ref} />

                                        <PrimaryButton
                                            customClassName="!py-2"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                submitForm().then(() => {
                                                    // setFieldValue('email', '')
                                                    // setFieldValue('message', '')
                                                })
                                            }}
                                        >
                                            {t(connect('send'))}
                                        </PrimaryButton>
                                    </div>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

export default ContactMessage
